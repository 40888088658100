const overlay = document.querySelector('.overlay')
const showOverlay = (el) =>{
   const header = document.querySelector('header')
   const modal = document.querySelector(el)
   overlay.classList.add('visible')
   modal.classList.add('visible')
   header.classList.add('hidden')
}

const closeOverlay = (el) =>{
   const header = document.querySelector('header')
   const modal = document.querySelector(el)
   overlay.classList.remove('visible')
   modal.classList.remove('visible')
   header.classList.remove('hidden')
}

const showModalButton = document.querySelector('.footer-contact button')

showModalButton.onclick =()=>{
   showOverlay('.footer-contact-modal');
}
overlay.onclick =()=>{
   closeOverlay('.footer-contact-modal');
}

